import { template as template_2c73275f3b91458ea5e2cd12c7af7aca } from "@ember/template-compiler";
const FKControlMenuContainer = template_2c73275f3b91458ea5e2cd12c7af7aca(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
