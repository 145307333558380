import { template as template_e85d47e8a5a0443d9dbd0b7e4260d8eb } from "@ember/template-compiler";
const FKText = template_e85d47e8a5a0443d9dbd0b7e4260d8eb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
