import { template as template_ec357af948664f3fadf1b4420780333c } from "@ember/template-compiler";
const FKLabel = template_ec357af948664f3fadf1b4420780333c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
